.landing-footer {
    background-color: #fff; /* Light background */
    padding: 20px;
    text-align: center;
}

.footer-container {
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
}

.footer-logo img {
    min-width: 150px;
}

.footer-logo img {
    max-width: 150px; /* Adjust logo size */
    margin-bottom: 20px;
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 800px; /* Maximum width for links section */
    margin-bottom: 20px;
}

.footer-header {
    flex: 1;
    margin: 0 20px;
    min-width:115px;
}

.footer-header h4 {
    margin: 0 0 10px;
}

.footer-header ul {
    list-style: none;
    padding: 0;
}

.footer-header ul li {
    margin-bottom: 5px;
}

.footer-header ul li a {
    text-decoration: none;
    
}

.footer-social-media {
    margin-bottom: 20px;
}

.footer-social-media a {
    margin: 0 10px;
    text-decoration: none;
    
}

.footer-bottom {
    border-top: 1px solid #ccc;
    padding-top: 10px;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    position: relative;
}

.close-modal {
    color:red;
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}
