/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif; /* Font for the entire page */
    background-color: #f5f5f5; /* Light background color */
    color: #5d6b82; /* Default text color */
    line-height: 1.6; /* Improved readability */
}

h1, h2, h3 {
    margin-bottom: 1rem; /* Space below headings */
    font-weight: 300!important;
}

a {
    text-decoration: none; /* Remove underline from links */
    color: #5d6b82; /* Link color */
    transition: color 0.3s ease; /* Smooth color transition */
}

a:hover {
    text-decoration:none;
    color: #0056b3; /* Darker color on hover */
}

.container {
    max-width: 1200px; /* Max width for content */
    margin: auto; /* Centering the container */
    padding: 20px; /* Padding around the content */
}

button {
    color: white; /* Button text color */
    border: none; /* No border */
    padding: 10px 15px; /* Padding inside the button */
    border-radius: 5px; /* Slightly rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

button:hover {
    background-color: #0056b3; /* Darker background on hover */
}
