.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader span {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 16px;
  color: #ffffff;
}

/* You can customize the spinner styles based on the chosen library (RingLoader) */
/* For example, if you're using react-spinners, you can use the following styles */

.loader .RingLoader {
  width: 80px;
  height: 80px;
}

.loader .RingLoader span {
  display: none;
}