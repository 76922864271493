.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    background-color: #ffffff;
    width:85%;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .modal-content label {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal-content select,
  .modal-content input[type="text"],
  .modal-content input[type="email"],
  .modal-content input[type="tel"] {
    width: 100%;
    padding:1rem;
    /* margin:1rem; */
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .modal-content input[type="checkbox"] {
    margin:1rem;
    width:2rem;
    height:2rem;
  }
  
  .modal-buttons button {
    margin:.5rem;
  }
  
  .modal-buttons button:hover {
    opacity: 0.8;
  }
  