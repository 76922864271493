/* Onboarding Container */
.onboardContainer {
    display:flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction:column;
    padding-bottom: 1rem;
    width:50%;
    min-width:215px;
    margin-top:1rem;
}

.onboarding-content {
    display:flex;
    justify-content: center;
    background: url('../../../public/infiniti-bg.png') no-repeat;
    background-position: 100% 100%;
}

.onboarding-image {
    /* width:50%; */
    max-width:400px;
}
/* Onboarding Title */
.onboarding-title {
    font-size:1.7rem;
}

    /* Onboarding Navigation */
.onboarding-navigation {
    display: flex;
    justify-content: space-between;
}

/* Previous Button */
.onboarding-previous {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.onboarding-previous:hover {
    background-color: #e0e0e0;
}

/* Next Button */
.onboarding-next {
    background-color: #4ea1d3;
    border: none;
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.onboarding-next:hover {
    background-color: #3b8bb8;
}

/* Save Button */
.onboarding-save {
    background-color: #67c04b;
    border: none;
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.onboarding-save:hover {
    background-color: #519238;
}

/* Form Input */
.onboardContainer form {
    margin-bottom: 16px;
}

.onboardContainer label {
    display: block;
    margin-bottom: 4px;
}

.onboardContainer input,
.onboardContainer select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.onboardContainer select {
    appearance: none;
    padding: 8px 30px 8px 8px;
    background-color: #fff;
    /* background-image: url('chevron-down.svg'); */
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 14px;
}

.onboardContainer input[type='number']::-webkit-inner-spin-button,
.onboardContainer input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


    
@media (max-width: 1171px) {
    .onboarding-content {
        background-image: none !important;
    }

}

@media (max-width: 768px) {
    .onboardContainer {
        width: 80%;
    }

}

@media (max-width: 448px) {
    .onboarding-content {
        margin:3rem 0 0 -3rem
    }
    .onboardContainer {
        align-items: center !important;
        justify-content: center !important;
        width: 95%;
        padding:1rem;
    }

}

