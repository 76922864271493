@media (max-width: 767px) {
    .tabs {
        flex-direction: column;
    }

    .tabs button {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 420px) {
    .content_container {
        width: 90%;
    }
}