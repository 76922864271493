.astrotextOptions {
    display:flex;
    flex-wrap:wrap;
    justify-content: space-around;
    align-items:center;
    height:75vh;
}

.astrotextOptions button {
    width:30vw;
    min-width:200px;
    min-height:165px;
    height:30%;
    font-size:2rem;
}