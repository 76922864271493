#messageHeader {
    text-align: center;
    margin: 1rem 0;
    padding: 1rem;
}

.messageHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    flex-wrap: wrap;
    margin: 2rem 0 0 0;
}

.campaignMessageContainer {
    width: 50%;
    min-width: 375px;
}

.campaignMessageContainer button {
    margin: 10px 2px 0 2px;
}

.campaignMessages {
    flex-direction:row;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
}


.message {
    margin: 1rem;
    padding: 1rem;
    max-width: 325px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.messageButtons button {
    margin: 0.3rem;
}

.msg_btn_holder {
    display: flex;
}

.msg_btn_holder button {
    margin: 0.3rem;
}

.countdown {
    margin-top: 2rem;
    font-size: 1rem;
}

.media-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between media items */
    margin-top: 1rem; 
}

.media-item {
    position: relative;
    display: inline-block;
    width: 100px; /* Fixed width for consistency */
    height: 100px; /* Fixed height for consistency */
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden; 
}

.media-item img {
    width: 100%; /* Image fills container */
    height: 100%; /* Image fills container */
    object-fit: cover; /* Maintain aspect ratio */
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 12px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.delete-button:hover {
    background-color: darkred; /* Darker red on hover for feedback */
}

.hidden-input {
    display: none; /* Hide the original file input */
}

.file-input-container {
    display: flex;
    align-items: center;
    margin: 1rem 0; /* Added margin for spacing */
}

.upload-button {
    margin: 0 0.5rem; /* Space evenly for the button */
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition */
}


@media (max-width: 347px) {
    .campaignBtns {
        align-items: stretch;
        flex-direction:column;
    }
  }

@media (max-width: 805px) {
    .campaignMessages {
        width: 90%;
    }
    .campaignMessageContainer {
        width: 90% !important;
    }
}
