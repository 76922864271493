/* :root {
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --main-font: 'Arial', sans-serif;
    --main-container-background: #f8f9fa;
} */

label {
    font-size:1rem;
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  /* Tooltip content styles */
  .tooltip .tooltip-content {
    visibility: hidden;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the element */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }
  
  /* Tooltip arrow styles */
  .tooltip .tooltip-content::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
  }
  
  /* Show the tooltip on hover */
  .tooltip:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }

.campaignFields {
    align-items: center;
    justify-content: space-between;
    width:90%;
    border:2px solid var(--primary-color);
    margin:0 auto;
    padding:1rem;
    height: 100%;
}

/* .campaignFields label {
    display: flex;
    flex-direction: column;
    font-size:1rem;
    /* align-items: flex-start;
    margin-bottom: 0.5rem;
} */

.campaignFields input,
.campaignFields textarea {
    flex: 1;
    margin-left: 1rem;
    padding: 0.5rem;
    font-size:1rem;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    width: 90%; /* Add this line */
    height: 2rem; /* Add this line */
}
.campaignFields textarea {
    height:8rem;
}

.saveAndCancelBtns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
}

.saveAndCancelBtns button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    cursor: pointer;
}

.saveAndCancelBtns button:hover {
    background-color: #0056b3;
}

.saveAndCancelBtns button:first-child {
    margin-right: 0.5rem;
}

.saveAndCancelBtns label {
    margin-bottom: 0;
}

.campaignGroups {
    display: flex;
    flex-wrap:wrap;
    text-align: center;
    /* align-items: center; */
    justify-content: space-around;
}

.AvailableContactGroups,
.SelectedContactGroups {
    flex: 1;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.AvailableContactGroups h1,
.SelectedContactGroups h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.AvailableGroup,
.SelectedGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f0f0f0;
    font-size:1rem;
    border-radius: 5px;
    margin-bottom: 10px;
}

.AvailableGroup h2,
.SelectedGroup h2 {
    font-size: 1.2rem;
    margin: 0;
}

button {
    padding: 5px 10px;
    font-size: 1rem;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.campaignContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-around;
    align-items: stretch;
    margin:auto auto;
    margin-left:-3rem;
    padding:1.5rem;
}

.eachCampaign {
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    text-align:left;
    /* border:3px solid var(--primary-color); */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin:1rem;
    min-width:200px;
    max-width:500px;
    width:85%;

    min-height:300px;
    padding:1rem;
}
.campaignBtns {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    align-items: flex-end;
    margin:1rem 0;
    /* margin-right:1rem; */
}
.campaignBtns button {
    margin:.2rem;
}




@media (max-width: 347px) {
    .campaignBtns {
        align-items: stretch;
        flex-direction:column;
    }
  }
