/* :root {
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --main-font: 'Arial', sans-serif;
    --main-container-background: #f8f9fa;
  } */
  
  .contact_group {
    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    padding:1rem;
    justify-content:space-around;
    align-items:center;
  }
  
  .contact_group .group_item {
      /* background-color: var(--main-container-background); */
      padding: 2rem;
      margin: 2rem;
      /* border-radius: 5px; */
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      text-align:center;
  }

    .contact_group a {
        font-size:1rem;
        margin:2px;
        text-decoration: underline;
    }    
  .contact_group a:hover {
      text-decoration: underline;
  }

  .group_management_buttons {
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
  }
  .group_management_buttons button {
    margin:.25rem;
  }

  .zContract {
      display:flex;
      flex-wrap:wrap;
      justify-content: center;
      align-items:flex-start;
      list-style:none;
      margin:0;
      padding: 0;
  }
  
  .zContract li {
      text-align:left;
      margin: 1rem;
  }

  @media (max-width: 448px) {
    .contact_group {
        margin-left: -3rem;
    }
    .group_item h2 {
      font-size: 1.3rem;
    }
}
