#terms_checkbox {
    display:flex;
    flex-direction:row;
    align-items: baseline;
    justify-content: flex-start;
}

#terms_checkbox input {
    width:20px;
    height:20px;
    margin:2rem;
}

.agreement-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .agreement-modal-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    max-width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .agreement-modal-content h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .agreement-modal-content p {
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  
  .agreement-modal-content #terms_checkbox {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
  
  .agreement-modal-content #terms_checkbox label {
    font-size: 0.9rem;
    margin-left: 0.5rem;
  }
  
  .agreement-modal-content button {
    margin-top: 1rem;
  }

  #agreementContainer label {
    font-size:1.5rem;
  }

  
  .astrotext-images {
    margin:1rem 0;
  }
  
  .astrotext-images img {
    width: 500px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .availableNumberContainer {
    margin:2rem 0;
    width:50%;
    min-width:200px;
    
  }
  .availableNumbersList {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    list-style:none;
  }


  .availableNumbersList li {
    border-radius:2%;
    padding:1rem;
    margin:1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1)
  }


  



  #stepContainer {
    display:flex;
    flex-direction: column;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
  }

  #stepContainer input, #stepContainer select {
        font-size:3rem;
        width:auto;
        text-align: center;
  }
  /* input[name='areaCode'] {
    text-align:center;
  } */