#confirmProfile {
    text-align: center;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .subtitle {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .trustProfile {
    padding: 20px;
    margin: 10px;
    border: 1px solid #ccc;
    text-align: left;
  }
  
  .authorized-members {
    font-size: 2rem;
    margin-top: 20px;
  }
  
  .authorized-members div {
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
  }