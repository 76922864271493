.landing-header {
    display: flex;
    flex-wrap: wrap;
    background: white;
    box-shadow: none;
    justify-content:space-around;
    align-items: center;
    width:100%;
    padding-top:1rem;
}

.landing-header nav ul {
    list-style-type: none;
    padding: 0;
}

.landing-header nav ul li {
    display: inline;
    margin: 0 15px;
}

.user_options {
    display:flex;
    justify-content: space-around;
    min-width: 200px;
    padding-left: 2rem;
    padding-right: 2rem;
}
.user_options a {
    margin: 0 1rem;
    padding: .5rem;
}

#free_trial {
    background-color: #f4f4f4;
    padding-left:2rem;
    padding-right:2rem;
    border-radius: 2rem;
}

#free_trial:hover {
    color:white;
    background-color: #0056b3;
}

/* Styles for hamburger menu */
.hamburger {
    display: none;
    font-size: 48px;
    cursor: pointer;
}

.landing-header nav {
    display: flex;
}

.landingLogo {
    width:250px;
}

/* Media query for smaller screens */
@media (max-width: 1160px) {
    .user_options {
        display:none;
    }

    .landingLogo {
        margin:1rem;
    }
    .landing-header {
        justify-content: flex-start;
        justify-content: space-between;
    }

    .landing-header nav {
        display:none;
        justify-content: flex-end;
        width:300px;
        margin:auto 1rem;
    }

    .landing-header nav ul {
        display:flex;
        width:300px;
    }

    .landing-header nav.open {
        display: block; /* show when menu is open */
        background: white;
        width: 100%;
        z-index: 10;
    }

    .hamburger {
        display: block; /* show hamburger icon */
        margin: 0 3rem;
    }

    .hamburger.open {
        display: block;
    }

    .landing-header nav ul {
        flex-direction: column; /* stack items vertically */
    }

    .landing-header nav ul li {
        margin: 10px 0; /* spacing between items */
    }
}

@media (max-width: 422px) {
    .landing-header {
        justify-content: flex-end !important;
    }
}