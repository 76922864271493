.outgoing-calls {
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}

.campaign-form {
  margin: 1rem 0;
}

.form-group {
  margin-bottom: 1.25rem;
}

.contact-group-bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.contact-group-bubble {
  font-size: 0.8rem;
  padding: 0.425rem;
  background-color: #f2f2f2;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-group-bubble:hover {
  background-color: var(--button-hover);
  color: white;
}

.contact-group-bubble.selected {
  background-color: var(--primary-color);
  color: white;
}

.selected-contact-groups {
  margin-top: 1rem;
}

.selected-contact-group {
  display: inline-block;
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
}

.selected-contact-group .delete-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}

.delete-icon:hover {
  text-decoration: underline;
}

.existing-campaigns .campaignBtns {
  display: flex;
  /* gap: 1rem; */
}

.existing-campaigns {
  margin-top: 2rem;
  width:50%;

}

.existing-campaigns h3 {
  margin-bottom: 1rem;
}

.existing-campaigns table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.25rem;
}

.existing-campaigns th, .existing-campaigns td {
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.existing-campaigns tr:nth-child(even) {
  background-color: #f9f9f9;
}

.campaignBtns button {
  border: none;
  padding: 0.625rem;
  border-radius: 5px;
  cursor: pointer;
}

.campaignBtns button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}
