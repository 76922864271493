.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(ff, ff, ff, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop-zone {
    color: white;
    position:relative;
    background:rgba(10, 10, 10, 0.5);
    display:flex;
    border: 2px dashed gray;
    min-width:70%;
    min-height:70%;
    justify-content: center;
    align-items:center;
}

.close-button {
    position:absolute;
    top:0;
    right:0;
    margin:1rem;
}