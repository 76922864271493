.questionnaire-modal .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
}

.questionnaire-modal .landingLogo {
    text-align: left; /* Align logo to the left */
    margin-bottom: 20px; /* Space between logo and header */
}

.questionnaire-modal .modal-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    width: 80%; /* Adjust width as needed */
    max-width:none;
    height: 80%; /* Set a height, adjust as needed */
    overflow-y: auto; /* Allow vertical scrolling */
}

.questionnaire-modal .name-fields {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px; /* Added margin for spacing */
}

.questionnaire-modal .name-field {
    flex: 1;
    margin-right: 10px;
}

.questionnaire-modal .name-field:last-child {
    margin-right: 0; /* Remove right margin from last element */
}

.questionnaire-modal h2 {
    margin-bottom: 20px;
}

.questionnaire-modal label {
    font-weight: bold;
    margin-top: 10px;
    display: block;
}

.questionnaire-modal input[type="text"],
.questionnaire-modal input[type="email"],
.questionnaire-modal select,
.questionnaire-modal textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.questionnaire-modal textarea {
    resize: vertical; /* Allow vertical resizing only */
}

.questionnaire-modal button[type="submit"] {
    background-color: #007BFF; /* Blue background */
    color: white; /* White text */
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.questionnaire-modal button[type="submit"]:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.questionnaire-modal h3 {
    font-size: 1.5rem; /* Size of the heading */
    margin-bottom: 10px; /* Space below the heading */
    font-weight: bold; /* Make the heading bold */
    color: #333; /* Dark grey color for the heading */
}

.questionnaire-modal label {
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center align the checkbox with text */
    margin-bottom: 10px; /* Space between each checkbox label */
    font-size: 1rem; /* Font size for the label text */
    color: #555; /* Darker grey for readability */
}

.questionnaire-modal input[type="checkbox"] {
    margin-right: 10px; /* Space between checkbox and label text */
    width: 20px; /* Size of the checkbox */
    height: 20px; /* Size of the checkbox */
    cursor: pointer; /* Change cursor to pointer on hover */
}

.questionnaire-modal label:hover {
    background-color: #f0f8ff; /* Light blue background on hover */
    border-radius: 5px; /* Slight rounding of the background */
}

.success-message {
    margin-top: 20px;
    padding: 10px;
    background-color: #dff0d8; /* Light green background */
    color: #3c763d; /* Dark green text */
    border: 1px solid #d6e9c6; /* Green border */
    border-radius: 5px;
}