#communicationTimeline {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
}

/* Headings */
#communicationTimeline h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
}

/* Phone Number Selector */
.phone-number-selector {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.phone-number-bubble {
    background-color: black;
    color: white;
    border-radius: 20px;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.phone-number-bubble:hover {
    background-color: #c0d3ff;
}

.phone-number-bubble.active {
    background-color: black;
    color: white;
}

/* Timeline Styles */
.timeline {
    display:flex;
    flex-direction: column;
    border-left: 2px solid #6b78ff;
    position: relative;
    padding-left: 20px;
}

.timeline-item {
    width:45%;
    margin-bottom: 20px;
    position: relative;
}

.timeline-item.inbound {
    align-self: flex-start;
}

.timeline-item.outbound {
    align-self: flex-end;
}

.timeline-point {
    width: 10px;
    height: 10px;
    background-color: #6b78ff;
    border-radius: 50%;
    position: absolute;
    left: -5px;
}

.timeline-content {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.timeline-content p {
    margin: 0;
}

/* Timestamp */
.timestamp {
    font-size: 12px;
    color: #888;
}

/* New Message Section */
.message-input {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.message-input input,
.message-input textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
}

.message-input input::placeholder,
.message-input textarea::placeholder {
    color: #aaa;
}

.message-input button {
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.message-input button:hover {
    background-color: #5a68e0;
}

.message-input .error {
    color: red;
    margin-top: 10px;
}