#contact_container {
    
}

.newBtn {
    margin:1rem auto;
}

.contactSearch {
    margin:1rem 0;
}

#contact_container textarea, #contact_container input {
    width:95%;
    padding:1rem;
    border-radius:1rem;
}

/** IMPORT CONTACT OVERLAY **/

#contactContainer {
    background:white !important;
    padding:.5rem 2rem;
}

.active {
    background-color: white;
    color:black;
}