.faq {
    padding: 50px 20px;
    text-align: center;
    background-color: #fff;
    background-image: url('../../../public/infiniti-bg.png');
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-position: 100% 50%;    



}

.faq h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
}

.faq-list {
    max-width: 800px;
    margin: auto;
    text-align: left; /* Align text to the left for readability */
}

.faq-item {
    border-bottom: 1px solid #ccc;
    padding: 15px 0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.faq-question h3 {
    margin: 0;
    font-size: 1.25rem;
}

.faq-answer {
    margin-top: 10px;
    color: #666;
}

.toggle-icon {
    font-size: 1.5rem;
    margin-left: 10px;
}
