.each_number {
  /* background-image: url('../../../public/infiniti-bg.png'); */
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: 110% 88%;

  max-width:600px;
  min-width:30%;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 2rem;
}

.assistantPhoneNumber {
  min-width:163px;
}

.toggle-collapse-btn {
  min-width: 134px;
}

.assistant-field:first-child {
  margin:0 0 1rem 0 !important;
}

.assistant-field {
  margin:1rem 0;
}

.phone-number-actions {
  margin-top: 1.25rem;
}

.phone-number {
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  /* color: var(--button-hover); */
  padding: 1rem;
  border-radius: 10px;
}

.update-prompt-button,
.view-conversation-button,
.add-escalation-contact-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.625rem;
  border-radius: 5px;
  margin-right: 0.625rem;
  cursor: pointer;
}

.update-prompt-button:disabled,
.view-conversation-button:disabled,
.add-escalation-contact-button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.prompt-textarea {
  width: 100%;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
}

.escalation-wrap {
  margin-top:1rem;
  margin-bottom:1rem;
}


.escalation-contacts-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.escalation-contact {
  margin: 1rem;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.escalation-contact button {
  margin:5rem 0;
}

.escalation-contacts-container button {
  margin:1rem;
}

.delete-contact-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 1rem;
  margin: 1rem;
  cursor: pointer;
}

.save-escalation-contact-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 1rem;
  margin: 1rem;
  cursor: pointer;
}


.language-bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content:space-evenly;
}

.language-bubble {
  font-size:.8rem;
  padding: 0.425rem;
  background-color: #f2f2f2;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.language-bubble:hover {
  background-color: var(--button-hover);
  color:white;
}

.language-bubble.selected {
  background-color: var(--primary-color);
  color: white;
}

.selected-languages {
  margin-top: 1rem;
}

.selected-language {
  font-size:1rem;
  display: inline-block;
  margin: 0.5rem;
  padding: 0.5rem;
  min-height:40px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.delete-icon:hover {
  text-decoration: underline;
}

.assistant-knowledgebases-section {
  margin-top: 1rem;
}

.assistant-actions button {
  margin:1rem;
  font-size:.75rem;
}



#knowledgebase-container {
  display:flex;
  flex-wrap:wrap;
  align-items:flex-start;
  margin: 2rem 0;
  /* justify-content: space-around; */
  /* margin:2rem; */
}

.knowledge {
  /* border:2px solid red; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-width:200px;
  padding:1rem;
}

.knowledgebase-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}

.kb_text {
  margin:1rem 1rem 2rem 1rem;
}

.knowledgebase-attachment {
  margin-top:1rem;
  margin-left:1rem;
}

.knowledgebase-icon {
  width: 5rem;
  height: 5rem;
  margin-right: 0.625rem;
  background-color: #f2f2f2; /* Placeholder for file icon background */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.knowledgebase-icon img {
  max-width: 80%; /* Adjust to fit the icon */
  max-height: 80%; /* Adjust to fit the icon */
}



.delete-icon {
  position:absolute;
  margin-top:-5rem;
  margin-left:5rem;
  width: 20px; 
  height: 20px;
  background-color: #f44336; 
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  cursor: pointer;
}

.delete-icon:hover {
  background-color: darkred; /* Darker shade on hover */
}

.knowledgebase-filename {
  font-size: 0.9rem; /* Adjust the font size as needed */
  color: #333;
}


@media (max-width: 1500px) {
  .each_number {
    max-width:90% !important;
  }

}

@media (max-width: 793px) {
  .each_number:first-child {
    margin-top:0;
  }
}


@media (max-width: 692px) {
  .each_number {
    background-image:none;
  }

}

@media (max-width: 692px) {
  .each_number {
    background-image:none;
  }

}

@media (max-width: 530px) {
  .each_number {
    background-image:none;
    width:150% !important;
  }

  .knowledge {
    width:100%;
  }

  .escalation-contact {
    width:100%;
  }

}

@media (max-width: 450px) {
  .each_number .newBtn {
    margin:.5rem 0 !important;
    font-size:.85rem;
  }
}

@media (max-width: 415px) {
  .each_number {
    background-image:none;
    width:150% !important;
  }

  .knowledge {
    width:100%;
  }

  .assistant-actions button {
    margin:.5rem 0 !important;
  }

}