/* Secondary script */

#sidebar-wrap {
  position: relative;
  font-size: 1.5rem;
}

.toggle-menu {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.sidebar {
  position: absolute;
  top: 0;
  left: -250px;
  width: 250px;
  z-index:100;
  height: 100vh;
  background-color: white;
  color: var(--secondary-color);
  transition: transform 0.5s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar.open {
  transform: translateX(250px);
}

.navigation {
  display:flex;
  flex-direction: column;
  justify-content:space-evenly;
  align-items:center;
  padding: 0;
  margin: 0;
  height:100%;
  list-style: none;
  transition: opacity 0.5s ease;
}

.navigation.fadeIn {
  opacity: 1;
}

.navigation.fadeOut {
  opacity: 0;
}

.navigation li {
  
}
