:root {
  --primary-color: #007bff;
  --secondary-color: #afc2d2;
  --main-font: 'Arial', sans-serif;
  --main-container-background: #99a0a8;
}

header {
    display: flex;
    justify-content:space-between;
    align-items: center;
    background-color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0rem 4rem;
}

.logo {
    margin:1rem 0;
}

.logo img {
    width: 250px;
    height: auto;
}

.contact-info {
    display: flex;
}

.contact-info a {
    font-size: 14px;
    color: white;
    text-decoration: none;
    margin-right: 10px;
}

.contact-info p {
    color: white;
}
