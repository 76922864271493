.elevate-cta {
    background-color: #f4f4f4;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 45vh;
    text-align: center;
}

.cta-title {
    font-size: 2.2em;
    color: #333;
    margin-bottom: 15px;
}

.cta-description {
    font-size: 1.1em;
    color: #666;
    margin-bottom: 25px;
}

.cta-buttons-container {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.cta-button-primary {
    padding: 12px 20px;
    background-color: #3498db; /* Call to action button color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button-primary:hover {
    background-color: #2980b9; /* Darker shade on hover */
}

.cta-button-secondary {
    padding: 12px 20px;
    background-color: #e0e0e0; /* Light secondary button color */
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button-secondary:hover {
    background-color: #ccc; /* Darker secondary color on hover */
}