.my_phone_container {
  background-color: white;
  }
  
  #my_phone_numbers {
    margin-left:-3rem;
    display:flex;
    justify-content: center !important;
    align-items: flex-start;
    flex-wrap:wrap;
  }
  


  .phone-number {
    display:flex;
    justify-content: space-between;
    width:100%;
  }
  
  .phone-number-actions {
    margin:1rem;

  }
  
  .phone-number-actions textarea {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
  }

  .phone-number-actions button {
    margin:0 .25rem;

  }
  
  button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
    pointer-events: none;
  }
  