body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
}

footer {
    display:block;
    padding: 20px 0;
    position: relative;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px; /* Adjust as per the desired container width */
    margin: 0 auto;
    padding: 0 20px; /* Optional padding */
}

.logo img {
    max-width: 150px; /* Adjust based on your logo size */
}

.contact-info {
    display: flex;
    flex-direction: column;
}

.contact-info p {
    margin: 5px 0;
}

.footer-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.footer-nav li {
    margin-right: 15px; /* Space between navigation links */
}

.footer-nav a {
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Make links bold */
}

.footer-nav a:hover {
    text-decoration: underline; /* Optional: underline on hover */
}

.social-media-links {
    display: flex;
}

.social-media-links a {
    margin-right: 10px; /* Space between social media icons */
    font-size: 1.5rem; /* Adjust icon size */
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .footer-nav ul {
        flex-direction: column; /* Stack the navigation links vertically on narrow screens */
    }
    
    .footer-nav li {
        margin-right: 0;
        margin-bottom: 10px; /* Space between links in mobile view */
    }
    
    .social-media-links {
        margin-top: 10px; /* Space above social media links for mobile view */
    }
}