.pricing-section {
    background-color: #f4f4f4; /* Soft background */
    padding: 50px 20px;
    text-align: center;
}

.pricing-title {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #333;
}

.pricing-subtitle {
    font-size: 1.2em;
    margin-bottom: 30px;
    color: #777;
}

.pricing-plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
}

.pricing-card {
    background-color: #fff; /* Clean white */
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-width: 200px; /* Card width */
    width:25%;
    transition: transform 0.3s;
}

.pricing-card:hover {
    transform: translateY(-5px);
}

.plan-title {
    font-size: 1.8em;
    color: #222;
    margin-bottom: 10px;
}

.plan-price {
    font-size: 2em;
    color: #e67e22; /* Highlight price */
}

.plan-features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.plan-button {
    padding: 10px 15px;
    background-color: #3498db; /* Button color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.plan-button:hover {
    background-color: #2980b9; /* Darker on hover */
}

.pricing-note {
    margin-top: 30px;
    font-size: 0.9em;
    color: #555;
}




@media (max-width: 435px) {
    .pricing-title {
        font-size:2rem;
    }
    .pricing-card {
        width:100%;
    }
}