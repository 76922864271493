.phone-call-transcriptions-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.transcriptions-list {
    flex: 1;
    font-size:.85rem;
    max-width: 350px;
    overflow-y: auto;
    padding: 15px;
    border-right: 1px solid #ccc;
}

.transcriptions-list ul {
    list-style-type: none;
    padding: 0;
}

.transcriptions-list li {
    background-color: #f7f7f7;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.transcriptions-list li:hover {
    background-color: #e0e0e0;
}

.transcription-content {
    flex: 3;
    padding: 15px;
}

.transcription-content .message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.transcription-content .message.assistant {
    background-color: #e2ffd9;
}

.transcription-content .message.user {
    background-color: #f1f0f0;
}

.transcription-content .message strong {
    display: block;
    margin-bottom: 5px;
}

.transcription-content .message small {
    display: block;
    margin-top: 10px;
    font-size: 0.8em;
    color: #666;
}
