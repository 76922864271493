.options {
    margin-bottom: 1rem;
  }
  
  button {
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  
  .phone_numbers_section {
    width: 100%;
    max-width: 600px;
    padding: 2rem;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .section_title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }