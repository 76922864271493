:root {
    --primary-color: black;
    --secondary-color: #333;
    /* --main-font: 'Open Sans', sans-serif; */
    --main-font: 'Montserrat', sans-serif;
    --background-color: #fff;
    /* --main-container-background: #f8f9fa; */
    /* --main-container-padding: 1rem;
    --button-padding: 1rem 1.5rem; */
    --button-hover: #0056b3;
    --button-font-size: 16px;
}
/* Apply general styles to all elements */
* {
    /* background-color: var(--main-container-background); */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App {
    background-color: black;
}

body {
    background-color: var(--background-color);
    font-family: var(--main-font);
    font-size: 1.2rem;
}

/* Style for headings */
h1, h2, h3, h4, h5, h6 {
    color: #333;
    font-weight: bold;
    margin-bottom:1rem;

}

/* Style for paragraphs */
p {
    color: #555;
    line-height: 1.5;
}

/* Style for links */
a {
    color: var(--secondary-color);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Style for images */
img {
    max-width: 100%;
    height: auto;
}

/* Style for buttons */
button {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: var(--button-hover);
}

/* Style for forms */
form {
    margin-bottom: 20px;
}

input,
textarea,
select {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    }

textarea {
    resize: vertical;
    min-height:7rem;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.content_container {
    margin-left:3rem;
    width:95%;
}

#container {
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white !important;
}

.Main {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height:100px; */
    min-height:90vh;
    width: 98%;
    margin: var(--main-container-padding);
    margin-top:0;
    padding-top:0;
    padding: var(--main-container-padding);
    background-color: white !important;
}

/* .Main h1 {
color: var(--primary-color);
margin-bottom: 1rem;
}

.Main p {
color: var(--secondary-color);
} */

.modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.Modal-close {
    float:right;
    font-size:1.5rem;
}

.tabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.tabs button {
    padding: 8px 12px;
    font-size: 14px;
    background-color: #e6e6e6;
    border: none;
    color: var(--secondary-color);
    cursor: pointer;
}

.tabs button.active {
    background-color: var(--primary-color);
    color: #fff;
}

button.create {
    padding: var(--button-padding);
    font-size: var(--button-font-size);
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    cursor: pointer;
}

button.create:hover {
    background-color: var(--primary-color);
}



.modalContainer {
    width:90%;
    margin:0 auto;
}