.how-it-works {
    padding: 50px 20px; /* Add padding for aesthetics */
    text-align: center;
    background-color: #ffffff; /* Ensure consistent background */
}

.features {
    display: flex;
    width: 80%;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    justify-content: space-around;
    margin: 20px auto; /* Centering the features */
}

.feature {
    background-color: #f9f9f9; /* Light background for features */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    padding: 20px; /* Padding around content */
    margin: 10px; /* Spacing between items */
    flex: 1 1 180px; /* Responsive width */
    transition: transform 0.2s; /* Smooth transition for hover effect */
}

.feature:hover {
    transform: scale(1.05); /* Slight scale effect on hover */
}

/* Adjust styles for the last feature that takes up two spaces */
.big-feature {
    flex: 0 0 100%; /* Full width for the last feature */
    max-width: none; /* Override max-width */
    margin-top: 20px; /* Space above the big feature */
}

.feature .icon {
    font-size: 40px; /* Size for icons */
    margin-bottom: 10px; /* Space between icon and title */
    padding:1rem;
    width:4rem;
    height:4rem;
    margin:1 rem;
}

.feature h3 {
    font-size: 1.5rem; /* Title font size */
    margin-bottom: 10px; /* Spacing below title */
}

.feature p {
    font-size: 1rem; /* Paragraph font size */
    color: #666; /* Text color for description */
}

/* Optionally, add media queries for responsiveness */
@media (max-width: 768px) {
    .features {
        flex-direction: column; /* Stack items on smaller screens */
        align-items: center; /* Center items */
    }
}