.account-section {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background: url('../../../public/infiniti-bg.png') no-repeat;
    background-position: 52% 50%;
}

.account-form {
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px; /* Added padding for more space */
}

.form-container {

}
.login-form,
.create-account-form {
    border: 1px solid #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Increased shadow for depth */
    background-color: #f9f9f9;
    min-width: 293px;
    width: 30%;
    padding: 30px; /* Increased padding for a more spacious feel */
    margin: 2rem auto;
    border-radius: 8px; /* Softens edges for a modern design */
    transition: box-shadow 0.3s; /* Smooth transition for hover effect */
}

.login-form:hover,
.create-account-form:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15); /* Darker shadow on hover */
}

.account-form h1, .account-form h2, .account-form h3, .account-form h4 {
    margin: 0 0 10px; /* Removes default margin and adds space below */
    text-align: center; /* Center-aligns headings */
}

.errors {
    margin-bottom: 20px; /* Space below error messages */
}

.error-message {
    color: red; /* Warning color for errors */
    font-weight: bold; /* Makes the error messages stand out */
    text-align: center; /* Center the error message */
}

.account-form button {
    border: none;
    border-radius: 5px;
    padding: 10px 20px; /* Adequate padding for button */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth background change */
}

.account-form button:hover {
    background-color: var(--button-hover-color); /* Hover effect for button */
}

.login-footer {
    text-align: center; /* Center the footer content */
    margin-top: 20px; /* Space above */
}

.forgot-password-link,
.create-account-link {
    color: var(--link-color); /* Use a variable for the link color */
    text-decoration: none; /* Remove underline */
}

.forgot-password-link:hover,
.create-account-link:hover {
    text-decoration: underline; /* Underline on hover for emphasis */
}






.companied-info,
.trust-signals {
    text-align: center; /* Center align for better reading */
    margin: 2rem 0; /* Added margin to separate sections */
}

.risk-free-info {
    font-style: italic;
    font-size: 0.9em; /* Slightly smaller font size */
    color: #555; /* Dark gray color for a soft look */
    text-align: center; /* Center the text */
    margin-bottom: 20px; /* Space below the blurb */
}


@media (max-width: 785px) {
    .create-account-form, .login-form {
        margin-top: 1rem;
        margin-bottom:1rem;
        width:90%;

    }
}


@media (max-width: 481px) {
    .company-info p {
        text-align:justify;
    }
}
    
@media (max-width: 323px) {
    .create-account-form, .login-form {
        padding:.5rem;
    }

}
