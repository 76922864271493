.astrovoice-key-features {
    padding: 50px 20px;
    text-align: center;
    background-color: #ffffff;
    background-image: url('../../../public/infiniti-bg.png');
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-position: 0% -3%;    
}

.astrovoice-key-features h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
}

.astrovoice-features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 90%;
    margin: 3rem auto;
}

.astrovoice-features-list {
    display:flex;
    justify-content: flex-start;
    text-align:left;
    width: 50%;
    /* min-width: 387px; */
}

.astrovoice-feature-container {
    margin: 0 3rem;
}

.astrovoice-feature {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin:2rem 0;
    border-radius: 10px;
    transition: transform 0.2s;
}

.astrovoice-feature:hover {
    transform: scale(1.05); /* Slight scale effect on hover */
}

.astrovoice-feature h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.astrovoice-feature p {
    font-size: 1rem;
    color: #666;
}

.astrovoice-feature-img {
    width: 45%;
    min-width:228.3px;
}

.astrovoice-feature-img img {
    width: 100%;
    border-radius:2rem;
}

.feature-description {
    margin: 0 0 0 2rem;
}

.reversed {
    flex-direction: row-reverse; /* Flips the layout for image and content */
}

.getStarted {
    border:1px solid gray;
    border-radius:1.6rem;
    font-size:1.5rem;
    padding:1rem;
}

.getStarted:hover {
    color:white;
    background-color: #0056b3;
}

.astrovoice-feature .icon {
    color: #dfe2e6;
    background: silver;
    border-radius:2rem;
    width: 10%;
    height: 10%;
    border:none;
}


@media (max-width: 1560px) {
    .feature-headline h1 {
        font-size: 1.4rem;
    }
    .feature-description h3 {
        font-size:1.2rem !important;
    }
}

@media (max-width: 1425px) {
    .feature-headline h1 {
        font-size: 1.2rem;
    }
    .feature-description h3 {
        font-size:1.0rem !important;
    }
}

@media (max-width: 1325px) {
    .astrovoice-feature-img {
        display:flex;
        align-items: center;
    }

    .getStarted {
        font-size:1.2rem;
        padding:.5rem;
    }
}

@media (max-width: 900px) {
    .astrovoice-feature-img {
        display:flex;
        align-items: center;
    }
}

@media (max-width: 857px) {
    .astrovoice-features-list {
        width: 100%;
    }

    .astrovoice-feature-container {
        margin-bottom:2rem;
    }

    .astrovoice-feature-img {
        display:flex;
        align-items: center;
    }

    .astrovoice-features {
        max-width:100%;
    }

}

@media (max-width: 465px) {
    .astrovoice-feature .icon {
        width:20%;
        height:20%;
    }

}

@media (max-width: 331px) {
    .astrovoice-feature .icon {
        width:40%;
        height:40%;
    }

}