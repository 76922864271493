.hero {
    position:relative;
    background-color: white;
    background-image: url('../../../public/infiniti-bg.png');
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-position: 100% -3%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
}
.hero-blurb {
    margin:5rem auto 0 auto;
    width: 70%;
}
.hero-content {
    border-radius: 10px;
    font-size: 1.5rem;
}

.h1 {
    font-size: 3rem;
    margin-bottom: 10px;
}


.cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between the buttons */
}


.cta-button {
    background-color: #dfe2e6 !important;
    color: black !important;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 2rem !important;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #e56d00; /* Darker shade on hover */
}

.learn-button {
    display:flex;
    align-items:center;
    background:none;
    border:none;
}

@media (max-width: 885px) {
    h1 {
        font-size:2.3rem;
    }
    .how-it-works {
        padding: 0 !important;
    }
}

@media (max-width: 608px) {
    h1 {
        font-size:1.7rem;
    }
    p {
        font-size:1rem;
    }
    .how-it-works {
        padding: 5px 10px !important;
    }
    .hero {
        background-size: 85%;
        min-height:450px;
    }
}